.auto-complete-container {
    display: flex;
    flex-direction: column;

    .auto-complete-input-container {
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 0px;
        height: 64px;
        width: 100%;
        padding: 0.375rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #151515;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #a8a8a8;
        appearance: none;
        border-radius: 5px;
        margin: 0px;

        &:focus-within {
            outline: #151515;
            border: 1.5px solid #151515;
            // padding: calc(0.375rem-0.75px) calc(1rem-0.75px);
        }

        &.expanded {
            border-radius: 5px 5px 0px 0px;
        }

        .auto-complete-label {
            transition: all 0.2s;
            position: absolute;
            top: 0;
            left: 0;
            padding: 1rem 1rem;
            pointer-events: none;
            transform-origin: 0 0;
            color: #817f7f;

            &::after {
                content: var(--autoCompleteLabelText);
            }
        }

        .auto-complete-input {
            flex-grow: 1;
            padding: 1rem 0rem;
            transition: all 0.2s;
            border: none;
            color: #151515;

            &:focus {
                outline: none;
            }

            &:not(:placeholder-shown) {
                padding-top: 1.625rem;
                padding-bottom: 0.625rem;

                &~.auto-complete-label {
                    color: #626262;
                    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);

                    &::after {
                        content: var(--autoCompleteFloatingLabelText);
                    }
                }
            }
        }

        .auto-complete-input-clear-icon {
            &:hover {
                fill: #151515;
                filter: invert(76%) sepia(10%) saturate(13%) hue-rotate(315deg) brightness(88%) contrast(89%);
            }
        }

        .auto-complete-input-toggle-menu-button {
            margin: 0px;
            padding: 5px;
            width: 50px;
        }
    }

    .auto-complete-dropdown-container {
        position: relative;
        top: 0px;
        padding: 0px;
        margin: 0px;
        z-index: 2000;

        .auto-complete-dropdown {
            position: absolute;
            // width: calc(100% - 23px - 12px);  // 23px and 12px are all paddings of parent elements, so that we match autocomplete input width
            width: 100%;  // not sure what changed, but with most recent commits this now only works with 100%
            padding: 0px;
            margin: 0px;
            list-style: none;
            box-sizing: border-box;
            border: 1.5px solid #151515;
            border-top: 0px;
            border-radius: 0px 0px 4px 4px;
            background-color: #fff;


            .auto-complete-dropdown-item {
                width: 100%;
                padding-left: 1rem;
                padding-top: 6px;
                padding-bottom: 6px;
                text-overflow: clip !important;
                cursor: pointer;

                &.highlighted {
                    background-color: #f1f1f1;
                }
            }
        }

        .auto-complete-dropdown-footer-container {
            margin-top: 12px;
            margin-bottom: 12px;
        }

    }
}
