.alert-primary {
    background: #FCF4FF;
    color: #9424C9;
    border: none;
}

.alert-info {
    // @include gradient;
    // @include font(aux);
    /* Gradients/Elements - 10% */

    background: linear-gradient(
        90deg,
        rgba(255, 132, 105, 0.1) 0%,
        rgba(232, 103, 103, 0.1) 23.04%,
        rgba(209, 74, 218, 0.1) 43.36%,
        rgba(125, 70, 245, 0.1) 64.01%,
        rgba(85, 102, 255, 0.1) 83.33%,
        rgba(51, 120, 247, 0.1) 100%
    );
    border: none;
}

.alert-dark {
    background: #F9F9FA;
    color: #151515;
    border: 1px solid #ECECEC;
}

.alert-danger {
    background: #FFEDED;
    color: #CF0000;
    border: none;
}

.alert-secondary {
    background: #F5F9FF;
    color: #335E9F;
    border: none;
}
