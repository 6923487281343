.credit-line-limited-time-offer {
    text-align: left;    
    
    .promotion-specific-container.purple-button-small {
        margin-left: inherit;        
    }
    .purple-button-small.btn {
        margin-left: inherit;
    }
    .full-amount {
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.198px;
        text-decoration: line-through;
    }
    .promo-amount {
        color: #56F;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.198px;
    }
    

}
.promo-text-subtext {
    padding: 0px;
    margin: 0px;
    color: rgba(0, 0, 0, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.154px;
}
.inline {
    display: inline;
    .promotion-specific {
        display: inline;
    }
    .highlighted {
        display: inline;
    }    
}
.limited-time-offer-text {
    color: #000;  
    display: inline;  
    border: none;
    .offer-highlight {
        display: inline;
        font-weight: 500;
    }
}