.card {
    &.mt-24 {
        margin-top: 24px;
    }
    .card-body {
        padding: 32px 23px;
    }
    &.card-tall-padding {
        padding: 48px 20px;
        gap: 12px;
    }
    &.gradient-border {
        @include gradient-border;
        .card-body {
            @include gradient-cover;
        }
        &.payment {
            padding: 2px;
            border-radius: 10px;
            .card-body {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                border-radius: 10px;
                img {
                    flex: 1 1 auto;
                    min-width: 128px;
                    max-width: 185px;
                }
                &-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-width: 120px;
                    max-width: 45%;
                    margin-left: 30px;
                    padding-left: 30px;
                    border-left: 2px solid lighten($os-gray, 30%);
                    * {
                        margin-bottom: 0;
                    }
                }
            }
            &.disabled {
                background: none !important;
                border: 1px solid rgba(21, 21, 21, 0.125);
                &:after {
                    background:none !important;
                }
            }
        }
    }
    &.dark {
        background-color: $os-gray-light;
        border: none;
        border-radius: 10px;
        .title {
            font-weight: 600;
        }
        .input-instructions {
            @include font('aux');
            padding-left: 12px;
            color: #727272;
            margin-top: 6px;
        }
    }
    &.thick-black-border {
        border: 9px solid #000;
        &.payment {
            padding: 2px;
            border-radius: 10px;
            .card-body {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                border-radius: 10px;
                img {
                    flex: 1 1 auto;
                    min-width: 128px;
                    max-width: 185px;
                }
                &-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-width: 120px;
                    max-width: 45%;
                    margin-left: 30px;
                    padding-left: 30px;
                    border-left: 2px solid lighten($os-gray, 30%);
                    * {
                        margin-bottom: 0;
                    }
                }
            }
            &.disabled {
                background: none !important;
                border: 1px solid rgba(21, 21, 21, 0.125);
                &:after {
                    background:none !important;
                }
            }
        }
        &.promo {
            margin-bottom: 1rem;
            .card-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 10px;
                padding: 2px;
                line-height: 1rem;
                .bold {
                    font-weight: 700;
                }
                p {
                    margin: 0px;
                }
            }
        }
        .flag {
            color: #FFF;
            background: #000;
            position: absolute;
            border-radius: 10px;
            top: -15px;
            left: 15px;
            padding: 3px;
        }
    }
    
    &.dark-promo-card {
        color: #FFF;
        background: #000;
        &.label {
            text-align: center;
            font-size: 15px;
            padding: 7px;
            margin-bottom: 7px;
        }
    }
    &.intro {
        border: 1px solid #151515;
        border-radius: 10px;
        .card-type-header {
            font-size: 32px;
        }
        .label {
            text-align: left !important;
            font-size: 15px;
        }
        .image {
            border: none;
            padding-right: 0px;
        }
    }
    &.intro-dark {
        background: #151515;
        color: #FFFFFF;
        border: none;
        border-radius: 10px;

        button {
            background: #FFFFFF;
            color: #151515;
        }
        .card-type-header {
            font-size: 32px;
        }
        .label {
            text-align: left !important;
            font-size: 15px;
        }
        .image {
            border: none;
            padding-right: 0px;
        }
    }
    &.credit-confirm {
        .img-text-lockup .image img {
            width: 56px !important;
        }
        .card-type {
            font-size: 24px;
            font-weight: 500;
            padding-left: 16px;
        }
        .img-text-lockup .image {
            max-width: 100%;
        }
        .img-text-lockup {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 0px !important;
        }
        .bottom-hr {
            margin-bottom: 0px;
        }
        .credit-line {
            width: 100%;
        }
        .credit-line .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #626262;
            font-size: 14px;
        }
        .number {
            color: #151515;
            font-size: 15px;
            font-weight: 600;
        }
    }
    &.basic-info {
        .card-body {
            display: flex;
            justify-content: space-between;
            &.basic {
                .light-text {
                    color: #AAAAAA;
                }
            }
            &.plus {
                background-color: #000000;
                color: white;
            }
            .card-body-info {
                border: 0px;
                padding: 0px;
                margin: 0px;
                max-width: fit-content;
                display: flex;
                justify-content: flex-start;
                padding-right: 15px;                
            }

            .card-body-info .reduced {
                max-height: 65px;
            }
        }
    }
    .card-footer {
        border: none;
        background-color: #f5f5f5;
        color: #000;
        padding-top: 2px;
        padding-left: 25px;
    }
    .flag-right {
        background-color: #5566FF;
        color: #fff;
        position: absolute;
        border-radius: 10px;
        top: -15px;
        right: 40px;
        display: inline-flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .sub-img-text {
        color: var(--monochrome-black, #151515);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
        padding: 8px 36px 15px 16px;
    }
    &.light-gray-background {
        background: #FAFAF9;
        padding-top: 20px;
        border: 0px;
        border-radius: 10px;
    }
}

.icon-card {
    background-color: #FFF;
    padding: 24px 16px;
    border: 1px solid #D0D0D0;
    border-radius: 8px;
    display: flex;
    gap: 16px;
    align-items: center;
    .icon {
        height: 24px;
        width: 24px;
    }
    .text {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        text-align: left;
    }
}
