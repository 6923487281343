.loading-container {
	height: 100%;
	display: flex;
	justify-content: center;
	gap: 12px;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin-top: 196px;

	h1 {
		font-size: 24px;
		line-height: 30px;
	}
	.spinner {
		@keyframes spin {
			100% {
				transform: rotate(360deg);
			}
		}

		margin-bottom: 12px;
		position: relative;
		display: flex;
		justify-content: center;
		width: 60px;
		height: 60px;
		overflow: hidden;
		background: linear-gradient(
			90deg,
			#ff8469 0%,
			#e86767 23.04%,
			#d14ada 43.36%,
			#7d46f5 64.01%,
			#5566ff 83.33%,
			#3378f7 100%
		);
		border-radius: 100%;
		animation: spin 1s infinite linear;
		.inner-circle {
			margin: auto;
			width: 49px;
			height: 49px;
			background-color: #fff;
			border-radius: 100%;
			z-index: 2;
		}

		.circle-corner {
			position: absolute;
			top: 0px;
			right: 0px;
			width: 30px;
			height: 30px;
			background-color: #ababab;
		}
	}
}
