.upload-fail-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 121px;
	text-align: center;
	justify-content: center;
	height: 100%;
	gap: 18px;
	h1 {
		font-size: 24px;
		line-height: 30px;
	}
	p {
		margin-bottom: 30px;
	}
}
