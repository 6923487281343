img {
    max-width:100%;
}

// Chrome, Edge, Safari
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background-color: $os-gray-light;
    border-radius: 10px;
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-thumb {
    width: 7px;
    height: 7px;
    background-color: $os-gray;
    border-radius: 10px;
}
// Firefox scrollbar
* {
    scrollbar-width: thin;
    scrollbar-color: $os-gray;
}