@import "../../scss/os-variables";

.main #header {
    margin-bottom: 20px;    
}
.subtitle {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.01em;
    margin-bottom: 24px;
}
.content-container {
    background-color: $os-gray-light;
    border: none;
    border-radius: 10px;
    padding:24px;
    position: relative;
    font-family: 'Inter';
    font-size: 15px;
    line-height: 24px;
    font-style: normal;
    color: #151515;
    .header {
        font-weight: 700;
        letter-spacing: -0.01em;
        display: block;
        margin-bottom: 12px;
        margin-top: 24px;
        &.top-header {
            margin-top: 0px;
        }
    }
    .text {
        font-weight: 400;
        letter-spacing: -0.02em;
        display: block;
    }
    .icon-lock-gray {
        height: 15px;
        position: absolute;
        display: inline-block;
    }
}