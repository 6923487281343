.progress {
    background: #d9d9d9;
    &.page-progress {
        height: 5px;
        .progress-bar {
            background: linear-gradient(
                90deg,
                #ff8469 0%,
                #fe6060 22.71%,
                #d14ada 40.32%,
                #7c41ff 56.81%,
                #5566ff 78.69%,
                #3378f7 100%
            );
        }
    }
}
