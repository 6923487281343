@import "../../scss/os-variables";

.option-container {
  background-color: $os-gray-light;
  border: none;
  border-radius: 10px;
  padding:24px;
  position: relative;

  .form-check-label {
    width: 100%;

    p {
      margin-bottom: 0px;
      width: 100%;
      .edit-address {
        float: right;
        text-decoration: underline;
      }
    }
  }
  hr {
    border-top: 1px solid #A8A8A8;
    margin-top: 24px;
    margin-bottom:24px;
  }

  // .edit-address {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   float: right;
  //   text-decoration: underline;
  // }
}

.help-body {
  .center {
    display: block;
    margin: 0 auto;
  }
  .safety-uncentered {
    margin-left: 1em;
    margin-bottom: 1em;
  }
  .body-message {
    margin-bottom: 24px;
  }
  button.btn {
    margin-top: 12px;
  }
}