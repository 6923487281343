$input-border-color: #0000003b;
$input-border-color-active: #1065e6;

.block-code-circular {
    display: flex;
    gap: 8px;

    .input {
        width: 40px;
        height: 40px;
        font-size: 16px;
        border: 1px solid $input-border-color;
        border-radius: 100%;
        text-align: center;

        &:focus {
            outline: none;
            border-color: $input-border-color-active;
            transition: border-color 300ms;
        }
    }
}

.block-code-default {
    display: flex;
    gap: 8px;

    .input {
        width: 40px;
        height: 40px;
        font-size: 16px;
        border-radius: 6px;
        border: 1px solid $input-border-color;
        text-align: center;

        &:focus {
            outline: none;
            border-color: $input-border-color-active;
            transition: border-color 300ms;
        }
    }
}

.block-code-underline {
    display: flex;
    gap: 8px;

    .input {
        width: 40px;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-bottom: 1px solid $input-border-color;
        text-align: center;

        &:focus {
            outline: none;
            border-color: $input-border-color-active;
            transition: border-color 300ms;
        }
    }
}