@import '_os-fonts.scss';

.img-text-lockup {
    display: flex;
    align-items: center;

    .image,
    .text {
        //max-width: 50%;
        flex-basis: 50%;
    }
    .image {
        border-right: 1px solid #d3d3de;
        padding-right: 32px;
    }
    .image-no-border {
        border-right: 0px;
    }
    .text {
        justify-content: center;
        .number,
        .label {
            display: block;
            text-align: center;
        }

        .number {
            @include font(h1);
        }
        .label {
            @include font(p);
        }
    }
    img {
        max-width: 100%;
    }
}

.card-bottom {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.01em;
    hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .label {
        align-self: stretch;
    }
}

.disclaimer {
    @include font(aux);
    color: #a3a3a3;
    display: block;    
}

.disclosure-link {
    color: #817f7f;
}

.fine-print {
    @include font(label-sm);
    color: #626262;
    display: block;    
    border-top: 1px solid rgba(168, 168, 168, .5);
}

.featured {
    display: flex;
    width: 375px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: #F6FFF4;
    color: #008600;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.154px;
}

.featured img {
    width: 106.522px;
    height: 14px;
    flex-shrink: 0;
}

.large-creditcard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 24px;
}

.large-creditcard img {
    width: 204.709px;
    height: 128.805px;
}

.limited-offer-container {
    display: flex;
    padding: 16px 15px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-radius: 8px;

    .limited-offer-text {
        align-self: stretch;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
        letter-spacing: -0.198px;
    }

    .full-amount {
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.198px;
        text-decoration: line-through;
    }

    .promo-amount {
        color: #56F;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.198px;
    }

    .limited-offer-subtext {
        color: rgba(0, 0, 0, 0.50);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.154px;
    }
}
.main #header {
    margin-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
    p {
        margin-bottom: 0px;
    }
    h1 {       
        font-family: "Ginto";
        margin-bottom: 18px;
        font-size: 24px !important;
        font-weight: 500 !important;
        line-height: 26.4px !important;
        letter-spacing: -0.48px !important;
        @include font(h1);
        &.reduced {
            @include font(h1-reduced);
        }
        &.gradient {
            @include gradient;
            @include gradient-clip;
        }
    }
}

div.opensky-footer {
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px !important;
        line-height: 14px !important;
        letter-spacing: -0.02em;
        color: $os-gray;
    }
}

footer {
    display: flex;
    justify-content: center;
}
