ul {
    text-align: left;
    &.checklist {
        @include list-reset;
        @include font(aux);
        > li {
            border-bottom: 1px solid $os-list-rule;
            background: url('#{$img-path}/icon-checkmark.svg') no-repeat left
                20px;
            background-size: 12.6px 9px;
            padding: 15px 0 11px 25px;
            &:last-child {
                border-bottom: none;
            }
            ul {
                margin-top: 8px;
            }
        }
    }
    &.link-selector {
        @include list-reset;
        li {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        a,
        button {
            text-decoration: none;
            display: block;
            width: 100%;
            color: $os-black;
            border-radius: 6px;
            border: 1px solid #a8a8a8;
            padding: 2px;
            position: relative;
            background: #fff;
            &:hover,
            &:active,
            &:focus {
                color: $os-black;
            }
            .gradient-cover {
                @include gradient-cover;
                padding: 3px 13px 7px;
            }
            &.promo-button {
                border-radius: 6px;
                border: 1px solid #D4D4D4;
                display: flex;
                padding: 12px 15px;
                height: 70px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                filter: opacity(0.6);
            }
            &.selected-button {
                border-radius: 6px;
                border: 2px solid #56F;
                height: 70px;
                filter: opacity(1);
            }
            .promo-text {
                color: #000;
                text-align: center;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 27px */
                letter-spacing: -0.198px;
                .highlighted {
                    color: #56F;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    letter-spacing: -0.198px;
                }
            }
            .recommended {
                @include gradient;
                @include font(aux);
                color: #fff;
                padding: 3px 9px;
                display: inline-block;
                position: absolute;
                right: 16px;
                top: 15px;
                border-radius: $border-radius;
                z-index: 3;
            }
            &.gradient-border {
                @include gradient-border;
                .gradient-cover {
                    padding: 4px 14px 8px;
                }
                .recommended {
                    top: 16px;
                    right: 17px;
                }
            }
        }
        .number,
        .label {
            display: block;
            line-height: normal;
        }
        .number {
            @include font(h1);
        }
        .label {
            @include font(label-sm);
            color: #000;
        }
    }
    &.features {
        @include list-reset;
        &.mt-36 {
            margin-top: 36px;
        }
        li {
            display: grid;
            grid-template-columns: 1fr 3fr;
            align-items: center;
            margin-bottom: 21px;

            @include font(p);
            font-weight: 500;
            &:last-child {
                margin-bottom: 0;
            }
            .icon {
                grid-column: 1 / 2;
                margin-right: 1rem;

                position: relative;
                img {
                    height: 44px;
                    width: 44px;
                }
                &:after {
                    @include gradient;
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 44px;
                    height: 44px;
                    max-width: 100%;
                    z-index: -1;
                    filter: blur(3px);
                    opacity: 0.2;
                    border-radius: 22px;
                }
                z-index: 2;
            }
            .icon-gray {
                @extend .icon;
                filter: grayscale(1);
                &:after {
                    background: #ffffff;
                    z-index: unset;
                }
            }
            .text {
                grid-column: 2 / 3;
            }
            .description {
                @include font(aux);
                color: #a3a3a3;
                display: block;
            }
        }        
    }
}
p + ul.link-selector {
    margin-top: 24px;
}
