.local-paragraph {
    line-height: 24px;
    width: 344px;
}

.local-paragraph-bold {
    line-height: 24px;
    width: 344px;
    font-weight: bold;
}

.local-container {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
}

.card-width {
    width: 345px;
}

.center-text {
    text-align: center;
}