$os-black: #151515;
$os-white: #ffffff;
$os-gray-light: #f9f9fa;
$os-gray: #817f7f;
$os-gray-dark: #626262;
$os-salmon: #ff8469;
$os-pink: #fe6060;
$os-magenta: #d14ada;
$os-purple: #5566ff;
$os-blue: #3378f7;
$os-gradient-1: $os-salmon;
$os-gradient-2: $os-pink;
$os-gradient-3: $os-magenta;
$os-gradient-4: $os-purple;
$os-gradient-5: $os-blue;
$os-button-radius: 50px;
$os-list-rule: #d3d3d3;
$os-space-standard: 36px;
