.app {
    @include media-breakpoint-down(sm) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
main {
    margin: 7px auto 36px;
    max-width: 410px;

    @media only screen and (max-width: 446px) {
        padding-left: 18px;
        padding-right: 18px;
        flex: 1 0 auto;
        width: 100%;
    }
    @media only screen and (max-width: 513px) {
        //overflow-x: hidden;
    }
}
.nav-rule {
}
.mobile-centered {
    height: 100%;
    @extend .d-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 3rem;
    > div {
        flex: 0;
        > *:last-child {
            margin-bottom: 0;
        }
    }
}
.row {
    margin-bottom: 24px;
    &.mt-12 {
        margin-top: 12px;
    }
    &.mt-27 {
        margin-top: 27px;
    }
    &.mt-36 {
        margin-top: 36px;
    }

    &.mb-8 {
        margin-bottom: 8px;
    }
    &.mb-38 {
        margin-bottom: 38px;
    }
    &.mb-54 {
        margin-bottom: 54px;
    }
}
.h-rule {
    @include rule();
    height: 0px;
    opacity: 0.5;
}
