.toc-body {
    background-color: $os-gray-light;
    border: none;
    border-radius: 10px;
    height: 462px;
}

.toc-rates {
    width: 100%;
    height: 100%;
    border: 1px solid #a8a8a8;
    border-radius: 10px;
    background-color: $os-white;
    overflow: hidden;
    
    .content {
        width: 100%;
        height: 100%;
        overflow-y: hidden;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
