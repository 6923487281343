// .form-floating>label,
.form-floating > .input-group > label {
    color: #626262;
}

.form-control {
    border-radius: 5px !important;

    &.error {
        border-left: none;
        outline: none;
        outline-style: none;
        box-shadow: none;

        border-color: $os-red-error !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &.icon-right {
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.input-group-addon {
    z-index: 5;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    background-color: inherit;
    color: $os-black;

    &.disabled {
        background-color: $input-disabled-bg;
    }

    &.error {
        left: 72px !important;
    }

    &.prefix.has-material-label {
        top: 37px;
    }
}

input.prefixed {
    padding-left: 2.5rem !important;
}

.input-icon-right {
    background-color: $input-bg;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;

    &.disabled {
        background-color: $input-disabled-bg;
    }
}

.input-edit {
    text-decoration: underline;
    cursor: pointer;
}

.input-group-error {
    color: $os-red-error;
}

.input-group-text {
    background-color: $input-bg;

    &.focused {
        border-width: 2px;
        border-color: $os-black;

        &.input-icon-right {
            border-left: none;
        }

        &:not(.input-icon-right) {
            border-right: none;
        }
    }

    &.error {
        border-color: $os-red-error;
    }
}

.form-control.error:focus {
    border-width: 2px;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.form-select:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-check:focus + .btn,
.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-close:focus {
    outline: none !important;
    box-shadow: none !important;
}

// .form-floating>.form-control,
.form-floating > .input-group > .form-control {
    // .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}

// .form-floating>label,
.form-floating > .input-group > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: transform 0.1s ease-in-out;
}

.form-floating > label,
.form-floating > .input-group > label {
    z-index: 1000;

    &.error {
        left: 57px;
    }
}

@media (prefers-reduced-motion: reduce) {
    // .form-floating>label,
    .form-floating > .input-group > label {
        transition: none;
    }
}

// .form-floating>.form-control,
.form-floating > .input-group > .form-control {
    padding: 1rem 0.75rem;
}

// .form-floating>.form-control::placeholder ,
.form-floating > .input-group > .form-control::placeholder {
    color: transparent;
}

// .form-floating>.form-control:focus,
.form-floating>.input-group>.form-control:focus,
// .form-floating>.form-control:not(:placeholder-shown),
.form-floating>.input-group>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

// .form-floating>.form-control:-webkit-autofill,
.form-floating > .input-group > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

// .form-floating>.form-control:focus~label,
.form-floating>.input-group>.form-control:focus~label,
// .form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.input-group>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 1;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

// .form-floating>.form-control:-webkit-autofill~label,
.form-floating > .input-group > .form-control:-webkit-autofill ~ label {
    opacity: 1;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

// number format prefix (hide when placeholder label is in start position)
.form-floating>.input-group>.form-control~div.prefix.has-material-label {
    display: none;
}
.form-floating>.input-group>.form-control:focus~div.prefix.has-material-label,
.form-floating>.input-group>.form-control:not(:placeholder-shown)~div.prefix.has-material-label {
    display: block;
}

.form-check {
    input[type='checkbox'] + label {
        &:before {
            content: none !important;
        }
    }
}

button.link-button {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.02em;
    padding: 10px 24px 10px 24px;
    color: #817f7f;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    width: auto;
    max-width: auto;
    text-align: auto;

    img {
        height: 10.25px;
    }
}

@media (max-width: 959.98px) {
    button.link-button {
    padding: 10px 8px 10px 8px;
    }
}

button.btn.btn-outline-dark,
button.btn.btn-outline-dark:hover {
    border: 2px solid $os-black;
    color: $os-black;
    background-color: white;
}

legend.field-header {
    margin-bottom: 10px;
}

.modal {
    padding: 0 !important;
}
.modal-dialog {
    margin: 5rem 0px 0px;
}

.modal-content {
    min-height: calc(100vh - 5rem);
}

.modal-body {
    margin-left: 1px;
    margin-right: 1px;
    overflow-x: hidden;
}

.label-disclosures {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        width: auto;
        height: 18px;
        flex: none;
        order: 1;
        flex-grow: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.02em;
        text-decoration-line: underline;
    }
    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #151515;
        flex: none;
        order: 0;
        flex-grow: 1;
    }
}

.icon-line {
    width: 100%;
}

.general-gradient-border {
    border-radius: 6px;
    padding: 2px;
    position: relative;
    & > span {
        @include gradient-cover;
        padding: 3px 13px 7px;
    }
    @include gradient-border;
}
.force-text-left {
    text-align: left !important;
}

.countdown {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 18px !important;
}

.countdown-light {
    color: #A8A8A8 !important;
}

.countdown-dark {
    color: #151515 !important;
}

.base-font-size {
    font-size: 1rem;
}
.gray-border {
    border-color: #a8a8a8;
}

.intro-gap {
    height: 60px;
    margin-top: -24px;
}

.bottom30 {
    margin-bottom: 30px;
}

.hidden {
    display: none;
}

.link-button-right {
    padding-right: 24px;
}

@media (max-width: 959.98px) {
    .link-button-right {
    padding-right: 8px;
    }
}

.light-border-left {
    border-left: 2px solid #cdcccc;
    padding-left: 12px;
}

.funding-source-title {
    color: #000000;
}

.funding-source-text {
    font-size: 13px;
    line-height: 18px;
}
.fundingTodayRadios {
    .funding-source-title {
        padding-top: 30px;
    }   
    .fundTodayRadio {

    }
    .fundTomorrowRadio {
        .promoLastChance {
            display: none;
        }
    }
    .fundTomorrowRadioFundLater {
        .form-check-label {
            margin-top: -20px;
        }
        .promoLastChance {
            display: block;
            color: red;
        }
    }
}


.card-border-error {
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 5px !important;
    border-color: $os-red-error !important;
}

.weight700 {
    font-weight: 700;
}

.full-width {
    width: 100%;
}

.no-margins {
    margin: 0px !important;
}

.no-left-margin {
    margin-left: 0px !important;
}

.no-bottom-margin {
    margin-bottom: 0px !important;
}

.bottom-36 {
    margin-bottom: 36px;
}

.bottom-16 {
    margin-bottom: 16px;
}

.bottom-24 {
    margin-bottom: 24px;
}

.close-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    align-self: stretch;
}

.card-border-error {
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 5px !important;
    border-color: $os-red-error !important;
}
