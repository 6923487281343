.banner-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.banner {
    display: flex;
    width: 425px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--monochrome-black-900, #2D2D2D);
}

.banner-text {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}
