.default {
    height: 100%;
	display: flex;
	justify-content: center;
	gap: 18px;
	align-items: center;
	flex-direction: column;
	text-align: center;
}
.payment-error {
    color: #626262;
    letter-spacing: -0.02em;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    line-height: 24px;
    flex:none;
}

.partial-payment-span {
    text-align: start;   
    span {
        p:first-child {
            float: left
        }
        p:nth-child(2) {
            float: right;            
        }
        &.totals {
            p {
                margin-bottom: 0;
            }
        }
    }
    hr {
        float: left;
        width: 100%;
    }
}

.partial-funding input.prefixed .error {
    padding-left: 5px !important;    
}
.partial-funding .input-group-addon.prefix.error {
    left: 42px !important;
}
.error-text {
    color: #cf0000;
}
.paymentTypesAccepted {
    float: right;
    margin-top: -5px;
}

.layawayBanner {
    color: #9424C9;
    background: #FCF4FF;
    border-radius: 5px;
    font-size: small;
    span {
        padding: 5px;
    }
    span img {        
        border: 0.4px solid #9424C9;        
        color: #9424C9;        
    }
}

.card {

    &.promo-border {
        border: none;
        background-color: #f5f5f5;
        &.payment {
            padding: 2px;
            border-radius: 10px;
            .card-body {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                border-radius: 10px;
                padding: 16px;
                img {
                    flex: 1 1 auto;
                    min-width: 128px;
                    max-width: 185px;
                }
                &-info {                    
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-width: 120px;
                    max-width: 45%;
                    margin-left: 30px;
                    padding-left: 30px;
                    border-left: 2px solid lighten(#817f7f, 30%);                    
                    * {
                        margin-bottom: 0;
                    }
                }
            }
            &.disabled {
                background: none !important;
                border: 1px solid rgba(21, 21, 21, 0.125);
                &:after {
                    background:none !important;
                }
            }
        }
        &.promo {
            margin-bottom: 1rem;
            .card-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 10px;
                padding: 2px;
                line-height: 1rem;
                .bold {
                    font-weight: 700;
                }
                p {
                    margin: 0px;
                }
            }
        }
        .flag {
            background-color: #007BFF;
            color: #fff;
            position: absolute;
            border-radius: 10px;
            top: -15px;
            left: 15px;
            padding: 3px;
        }
        .flag-right {
            background-color: #007BFF;
            color: #fff;
            position: absolute;
            border-radius: 10px;
            top: -15px;
            right: 30px;
            padding: 3px;
        }
        .promo-description {
            color: #000;
        }
    }
    .card.promo-border.card-footer {
        border: none !important;
        background-color: #f5f5f5 !important;
        color: #000 !important;
        margin-top: -20px !important;
        padding-top: 2px !important;
        padding-left: 25px !important;
    }
}
