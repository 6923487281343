button.btn {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 15px 36px;

    &.btn-primary {
        background: $os-black;
    }

    &.gradient {
        @include gradient;
    }

    .arrow {
        display: inline-block;
        width: 14px;
        height: 10px;
        background: url("#{$img-path}/icon-arrow-white.svg") no-repeat center center;
        background-size: contain;
        margin-left: 4px;
    }

    .arrow-black {
        display: inline-block;
        width: 14px;
        height: 10px;
        background: url("#{$img-path}/icon-arrow-black.svg") no-repeat center center;
        background-size: contain;
        margin-left: 4px;
    }

    &:disabled {
        background: $os-gray-light;
        color: #a8a8a8;
        opacity: 1;
    }

    &.link-sm {
        @include font(aux);
        padding: 0;
        width: auto;
        border: none;
        background: none transparent;
        text-decoration: underline;
        font-weight: 600;
        color: #000000;

        &:disabled {
            background-color: transparent;
            color: #a8a8a8;
            text-decoration: none;
        }
    }

    &.btn-simple {
        /*
        color: $os-gray;
        border: 1px solid #a8a8a8;
        width: auto;
        padding: 2px 18px;
        */
        display: flex;
        height: 54px;
        padding: 6px 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 38px;
        border: 1px solid var(--solids-blacks-black-150-eeeeee, #ECECEC);
        color: var(--solids-blacks-black-600767676, #767676);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 120% */
        letter-spacing: -0.3px;

    }

    &.link-button {
        width: 2em;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.02em;
        padding: 10px 24px;
        color: #817f7f;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        display: inline;
        text-align: auto;
    }
    &.purple-button {
        display: flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 13.077px;
        border-radius: 100px;
        background: #5B66FB;
        width: fit-content;
    }
    &.purple-button-small {
        display: flex;
        padding: 3.106px 9.317px;
        justify-content: center;
        align-items: center;
        gap: 7.764px;
        border-radius: 6.212px;
        background: #56F;
        color: var(--monochrome-white, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
        width: fit-content;
    }
    &.purple-button-hollow {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 7.862px;
        border-radius: 60.119px;
        border: 1px solid #5B66FB;
        color: #5B66FB;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 10.821px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 14.068px */
        background: #FFF;
        width: fit-content;
    }
}
