.no-select {
    >select.form-select {
        +label {
            transform: none;
        }
    }
}

select.form-select {
    box-sizing: border-box;
    border: 1px solid #a8a8a8;
    margin: 1px;

    &:focus {
        outline: none;
        border: 2px solid #151515;
        box-shadow: none;
        margin: 0px;
    }

    +label {
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }
}

.select-dropdown-container.error .form-select {
    border-color: $os-red-error !important;
}

.withdraw-application {
    select {
        padding-bottom: 0.2rem !important;
    }
    select > option[value=""] {
        display: none;
    }
}