.carousel {
    padding-top: 20px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 26px;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 22px;
    }
    &-indicators {
        button[data-bs-target] {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border: none;
            background: $os-black;
            opacity: 0.4;
            &.active {
                opacity: 1;
            }
        }
    }
}
