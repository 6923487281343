.document-selection-page {
	align-items: center;

	h1 {
		font-size: 24px;
		line-height: 30px;
	}
	p {
		margin-bottom: 36px;
	}
	.document-selection {
		margin-bottom: 36px;
		font-weight: 600;
		font-size: 15px;
		.select-dropdown-container {
			margin-bottom: 36px;
			select.form-select {
				padding-top: 22px;
				padding-bottom: 18px;
				height: 64px;
			}
		}
		.photo-document-upload-container {
			margin-bottom: 36px;
			font-size: 13px;
			font-weight: 400;
			.photo-document-upload-box {
				font-weight: 500;
				font-size: 13px;
				.photo-document-upload-form-label.form-label {
					text-decoration: underline;
				}
			}
		}
	}
	button.btn {
		width: 100%;
	}
}
