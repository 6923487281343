.main {
    margin: 7px auto 0px;
}

.card-title {
    align-self: stretch;
    color: var(--Monochrome-Black, #151515);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 19.8px */
    margin-top: 16px;
}

.card-sub-title {
    align-self: stretch;
    color: var(--Monochrome-Black, #151515);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
}

.inter-paragraph {
    color: var(--solids-blacks-black-1000151515, 151515);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.16px;
}

.legend-margins {
    margin-bottom: 12px;
}

.header-margins {
    margin-top: 19px !important;
}
