.center-modal {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.modal-dialog-dimensions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    border-radius: 40px 40px 0px 0px !important;
    background: #FFF;
}

.modal-header-dimensions {
    width: 100%;

    .btn-close {
        margin: 0px !important;
    }
}

.downsell-navbar-logo {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.opensky-logo-dimensions {
    width: 77px;
    height: 25px;
}

.downsell-heading {
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; 
    letter-spacing: -0.28px;
    width: 307px;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-text {
    align-self: stretch;
    color: var(--solids-blacks-black-600767676, #767676);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.13px;
}