.form-switch {
    margin: 0 auto;
    background: #3378f7;
    width: 54px;
    height: 24px;
    border-radius: 40px !important;
    position: relative;
    input[type='checkbox'] {
        display: none;
    }
    &:before {
        content: 'YES';
        color: #fff;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10px;
        line-height: 18px;
        letter-spacing: -0.02em;
        font-weight: 500;
        opacity: 1;
        transition: all 0.25s ease;
    }
    &:after {
        content: 'NO';
        color: #fff;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10px;
        line-height: 18px;
        letter-spacing: -0.02em;
        font-weight: 500;
        opacity: 0;
        transition: all 0.25s ease;
    }
    & label {
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50px;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;
        z-index: 1;
    }
    & input[type='checkbox']:checked + label {
        transition: transform all 0.25s ease-in-out;
        transform: translate(165%, -50%);
    }
    &:has(input[type='checkbox']:checked) {
        &:before {
            transition: all 0.75s ease;
            opacity: 1;
        }
        &:after {
            transition: all 0.75s ease;
            opacity: 0;
        }
    }
}

.form-switch-disabled {
    background: gray !important;
    &:before {
        opacity: 0;
    }
    &:after {
        background: none !important;
        opacity: 1;
    }
}

.card-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    .nav {
        border: 1px solid #151515;
        border-radius: 2.5rem;
        padding: 10px;
        width: fit-content;
    }
    .nav-item a {
        border: 0px;
        border-radius: 2.5rem;
        background-color: #FFFFFF;
        color: #151515;
        display: block;
        padding: '10px';
    }
    .nav-link.active {
        background-color: #151515;
        color: #FFFFFF;
    }
}
