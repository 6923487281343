.layout-container {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: #f7f7f7;

    >div.row {
        height: 100%;

        >div {
            height: 100%;
            padding: 0;
        }

        .content {
            flex: auto;

            .topnav {
                display: flex;
                height: 60px;
                align-items: center;
                border-bottom: 1px solid #ddd;

                div.topnav-main {
                    display: flex;
                    flex-direction: row;
                    padding: 0 1.625rem;
                    width: 100%;
                    align-items: center;
                    justify-items: center;
                    justify-content: space-between;
                }
            }

            .main-content {
                margin: 0;

                .content-page {
                    padding: 10px 15px;
                }
            }
        }
    }
}

.link {
    color: blue;
}