
@font-face {
    font-family: "Ginto";
    src: url("#{$font-path}/ABCGintoNord-Medium-Trial.otf") format("opentype"),
    url("#{$font-path}/ABCGintoNord-Medium-Trial.woff") format("woff"),
        url("#{$font-path}/ABCGintoNord-Medium-Trial.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: "Inter";
    src: url("#{$font-path}/Inter-Regular.otf") format("opentype"), url("#{$font-path}/Inter-Regular.woff") format("woff"),
        url("#{$font-path}/Inter-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("#{$font-path}/Inter-Medium.otf") format("opentype"), url("#{$font-path}/Inter-Medium.woff") format("woff"),
        url("#{$font-path}/Inter-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("#{$font-path}/Inter-SemiBold.otf") format("opentype"), url("#{$font-path}/Inter-SemiBold.woff") format("woff"),
        url("#{$font-path}/Inter-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}
