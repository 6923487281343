.navbar {
    min-height: 57px;
    a {
        @include font(aux);
        padding: 10px 24px;
        color: $os-gray;
        img {
            height: 10.25px;
        }
        &.logo {
            padding: 0;
            img {
                height: 57px;
            }
        }
        &.hambuger {
            position: relative;
            display: flex;
            img {
                float: right;
                position: relative;
                top: -70px;                
            }
        }
    }
    &.has-logo {
        margin-bottom: 30px;
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid #eaeaea;
        }
    }  
} 

.navbar-gradient {
    @include gradient;
    @include gradient-clip;
    font-weight: 600;
}

.navbar-logo {
    display: flex;
    align-items: center;
}
