.avatar-list {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 26px;
    position: relative;
    min-width: 513px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 18px;

    .avatar {
        min-width: 81px;
        height: 81px;
        border-radius: 50%;
        .gradient-border {
            @include gradient-border;
            height: 100%;
            position: relative;
            padding: 2px;
            border-radius: 50%;
            &:after {
                border-radius: 50%;
            }
            .gradient-cover {
                @include gradient-cover;
                height: 100%;
                border-radius: 50%;
            }
        }
        .avatar-image {
            border-radius: 50%;
        }
    }
}
