.address-lookup-container {
  .back-to-address-lookup-link-container {
    margin-top:12px;
    /*
    span {
      text-decoration: underline;
      color: "#0000EE" !important;
      float: right;
      font-size: 13px;
      font-weight: 500;
    }
    */
    color: var(--solids-blacks-black-700626262, #626262) !important;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
    text-decoration-line: underline;
  }
}
