.safety-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    img {
        width: 100%;
        height: 100%;
    }
}