.form-control {
    height: 64px;
}
.form-control,
.form-floating {
    input {
        &:focus {
            border-width: 2px;
        }
    }
}
.form-floating {
    margin-top: 12px;
    > label {
        color: $os-gray-dark;
    }
    .form-control {
        height: 64px;
    }
}
fieldset {
    margin-top: 30px;
    legend {
        @include font(p);
        font-weight: 600;
        margin-bottom: 24px;
        + p.helper {
            transform: translateY(-12px);
        }
    }
    + .form-input {
        label {
            margin-top: 30px;
        }
    }
}
.form-check-input[type="radio"] {
    margin-right: 12px;
    position: absolute;
    opacity: 0;
    left: -100px;
    &:checked {
        + label {
            &:after {
                display: block;
                content: "";
                height: 14px;
                width: 14px;
                background: $os-black;
                top: 50%;
                position: absolute;
                left: 5px;
                border: 1px solid $os-black;
                border-radius: 12px;
                transform: translateY(-50%);
                border-radius: 7px;
                z-index: 2;
                border: none;
            }
        }
    }
}
.form-check {
    position: relative;
    overflow: hidden;
    @include font(p);
    color: $os-gray-dark;
    label {
        padding-left: 12px;
        &:before {
            display: block;
            content: "";
            height: 24px;
            width: 24px;
            background: #fff;
            top: 50%;
            position: absolute;
            left: 0;
            border: 1px solid $os-black;
            border-radius: 12px;
            transform: translateY(-50%);
        }
    }
}
.validation-code {
    margin-bottom: 18px;
    justify-content: center;
    > .col {
        @include media-breakpoint-down(sm) {
            padding-inline: 0.406rem;
            max-width: 3.75rem;
        }
    }
    input {
        text-align: center;
        font-size: 32px;
        @include media-breakpoint-down(sm) {
            padding-inline: 0.15rem;
        }
        &:focus {
            border-width: 2px;
        }
    }
    label {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
.form-input {
    label {
        @include font(p);
        font-weight: 600;
        margin: 24px 0 12px;
    }
    .gradient-border {
        @include gradient-border;
        position: relative;
        padding: 2px;
        border-radius: 6px;
        position: relative;
        .input-group-addon {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
            background-color: #fff;
        }
        .gradient-cover {
            padding-left: 2rem;
            &:focus {
                outline: none;
                border-color: transparent;
                box-shadow: none;
            }
        }
    }
}
.form-input {
    label {
        @include font(p);
        font-weight: 600;
        margin: 24px 0 12px;
    }
    .thick-black-border {
        border: 8px solid #000;
        position: relative;
        padding: 2px;
        border-radius: 6px;
        position: relative;
        .form-control {
            border: none;
        }
        .prefix {
            font-size: 22px;
        }
        ::placeholder {
            font-size: 22px;
        }
    }
}
.form-input,
.form-floating {
    .input-instructions {
        @include font("aux");
        padding-left: 1rem;
        color: #727272;
        margin-top: 6px;
    }
}
.input-secure {
    position: relative;
    .form-control {
        padding-right: 3rem;
    }
    &::after {
        content: "";
        position: absolute;
        top: 1.75rem;
        z-index: 3;
        transform: translateY(-50%);
        right: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
        background: url("#{$img-path}/icon-lock-gray.svg") no-repeat center center;
        background-position: cover;
    }
}
.input-error {
    position: relative;
    .form-control {
        padding-right: 3rem;
    }
    &::after {
        content: "";
        position: absolute;
        top: 1.75rem;
        z-index: 3;
        transform: translateY(-50%);
        right: 0.75rem;
        width: 2rem;
        height: 2rem;
        background: url("#{$img-path}/icon-error.svg") no-repeat center center;
        background-position: cover;
    }
}
.icon-warning {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto 18px;
}
.icon-question {
    display: block;
    width: 36px;
    height: 36px;
    margin: 0 auto 12px;
}

.icon-lock-gray {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto 18px;
}

.main {
    fieldset {
        legend {
            color: var(--solids-blacks-black-1000151515, #151515);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 18.2px */
        }
    }
}
