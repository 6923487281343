
.promotion-specific-container {

}

.promotion-specific {
    color: #151515;
}

.promotion-specific#TenPercentOffFullyFunded {

}
