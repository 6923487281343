.photo-document-upload-container {
	text-align: center;
	.photo-document-upload-box {
		height: 100px;
		box-sizing: border-box;
		border: 1px dashed #a8a8a8;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		position: relative;
		align-items: center;
		padding-top: 21px;
		margin-bottom: 12px;
		&.file-chosen {
			background: #f9f9fa;
			border: none;
		}
		.file-icon {
			margin-bottom: 6px;
		}
		.clear-file-upload {
			position: absolute;
			top: 15px;
			right: 22px;
		}
		.image-upload {
			margin-bottom: 6px;
		}
		.photo-document-upload-form {
			&.hidden {
				display: none;
			}
			.photo-document-upload-form-input {
				display: none;
			}

			.photo-document-upload-form-label {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}
