.app-status-approved {
    // @include gradient;
    // @include font(aux);
    /* Gradients/Elements - 10% */

    background: linear-gradient(
        90deg,
        rgba(255, 132, 105, 0.1) 0%,
        rgba(232, 103, 103, 0.1) 23.04%,
        rgba(209, 74, 218, 0.1) 43.36%,
        rgba(125, 70, 245, 0.1) 64.01%,
        rgba(85, 102, 255, 0.1) 83.33%,
        rgba(51, 120, 247, 0.1) 100%
    );
}

.app-status-info {
    background: #f5f9ff;    
    color: #335E9F !important;    
}

.app-status-error {
    background-color: red;
}

.app-status-closed {
    background: #f9f9fa;
}

.app-status-docrequest {
    background: #fcf4ff;
    color: #9424C9 !important;    
}

.app-status {
    color: $os-black;
}

.app-info-status-message-icon {
    width: 24px;
    height: 24px;
}

.app-info-status-app-icon {
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
}

.app-description {
    @include font(aux);
    color: #a3a3a3;
    display: block;
}
