.layout-container {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: #f7f7f7;

    >div.row {
        height: 100%;

        >div {
            height: 100%;
            padding: 0;
        }

        .content {
            flex: auto;

            .topnav {
                display: flex;
                height: 60px;
                align-items: center;
                border-bottom: 1px solid #ddd;

                div.topnav-main {
                    display: flex;
                    flex-direction: column;
                    padding: 0 1.625rem;
                    width: 100%;
                    align-items: center;
                    justify-items: center;
                }
            }

            .main-content {
                margin: 0;

                .content-page {
                    padding: 10px 15px;
                }
            }
        }
    }
}
div.opensky-footer {
    padding: 10px;
    .applicationFlowLayout-footersection {
        padding: 15px;
        .applicationFlowLayout-disclaimers {
            display: flex;        
            line-height: 18px;
            font-family: 'Inter';
            font-style: normal;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.01em;
            color: #767676;
            ol {
                padding-inline-start: 10px;
            }
            li:not(:last-child) {
                margin-bottom: 12px;
            }
        }
        .applicationFlowLayout-copyright {
            line-height: 14px;
            font-family: 'Inter';
            font-style: normal;
            font-size: 10px;
            font-weight: 400;
            letter-spacing: -0.02em;
            color: #626262;

        }
        .applicationFlowLayout-fdic {
            margin-top: 15px;
        }
    }
}

.divider {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    span {
        width: 1px;
        height: 24px;
        background: rgba(0, 0, 0, 0.10);
    }
}

.affiliate-logo {
    width: 100px;
}

button.link-button {
    color: var(--solids-blacks-black-700626262, 626262);
    text-align: center;
    font-family: Inter;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-decoration-line: underline;
}
