@media only screen and (max-width: 513px) {
    .intro-container {
        border: 0px solid !important;
        margin: auto !important;
    }
}

.intro-container {
    .promoBorder {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        align-items: center;
        gap: 36px;
        border-radius: 8px;
        border: 1px solid var(--monochrome-black-400, #A8A8A8);
        background: var(--monochrome-white, #FFF);
    }
    .card-body {
        padding: 0px !important;
        width: 100%;
    }
    .img-text-lockup {
        padding-bottom: 24px !important;
        display: flex !important;
        justify-content: space-between !important;
    }
    .text {
        flex-basis: auto !important;
        max-width: 100% !important;
        color: var(--monochrome-black, #151515);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 26.4px */
    }
    .label {
        align-self: stretch;
        color: var(--monochrome-black, #151515);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
        text-align: left !important;
        margin-top: 4px;
    }
    .image img {
        display: flex;
        width: 114.904px !important;
        height: 72.299px !important;
    }

    .image {
        display: flex !important;
        justify-content: flex-end !important;
        border: 0px !important;
        padding: 0px !important;
        justify-content: flex-end;
        align-items: center;
    }

    .no-annual-fee-ribbon {
        position: absolute;
        width: 74.12px;
        height: 59.19px;
        right: -8px;
        top: -6px;
    }

    .limited-offer-text {
        color: #000;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 23.4px */
        margin-top: 12px;
    }
    .limited-offer-subtext {
        color: rgba(0, 0, 0, 0.50);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
        margin-bottom: 24px;
    }
    .purple-highlight {
        color: #5B66FB;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
    }
    .strike {
        text-decoration: line-through;
    }
    .blue-text {
        color: #3378f7;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 18.2px */
    }
    .darker-text {
        color: var(--solids-blacks-black-1000151515, #151515);
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 18.2px */
    }
    .gray-strike {
        color: var(--monochrome-black, #151515);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.14px;
        text-decoration: line-through;
        opacity: 0.5;
    }
    ul.checklist {
        margin-bottom: 36px;
    }
    ul.checklist > li {
        background: none !important;
        padding-left: 0px !important;
        display: flex !important;
        justify-content: space-between !important;
        padding-top: 12px !important;
        padding-bottom: 18px !important;
        font-size: 14px !important;
        //padding-right: 19px !important;
        color: var(--monochrome-black, #151515);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.14px;
    }
    .button-fine-print {
        display: flex;
        justify-content: space-between;
        color: var(--Monochrome-Black, #151515) !important;
        opacity: 0.5;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        letter-spacing: -0.1px;
        padding-top: 24px;
        .underline {
            text-decoration-line: underline;
        }
    }
}
