.card-info-container {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid var(--Monochrome-Black-100, #F4F4F4);
    background: #FFF;
    box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.05);
    .card {
        width: 100%;
    }
    .card-horizontal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 39px;
        img {
            width: 100px;
            height: 69px;
        }
        .divider {
            width: 1px;
            height: 44px;
            background: rgba(0,0,0,0.20);
        }
        .credit-limit {
            color: var(--solids-blacks-black-1000151515, #151515);
            font-family: "Ginto";
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px; /* 111.111% */
            letter-spacing: -0.72px;
        }
        .credit-limit-text {
            display: flex;
            justify-content: center;
            color: var(--Monochrome-Black, #151515);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 18.2px */
        }
    }
}

.card-bottom {
    .balance-due {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #A8A8A8;
        padding-bottom: 8px;
        margin-top: 20px;
        margin-bottom: 8px;
        .balance-text {
            color: var(--solids-blacks-black-1000151515, #151515);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 160% */
            letter-spacing: -0.3px;
        }
        .balance-numbers {
            color: var(--solids-blacks-black-1000151515, #151515);
            text-align: right;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 160% */
            letter-spacing: -0.3px;
        }
    }
    .fees {
        display: flex;
        flex-direction: column;
        color: var(--solids-blacks-black-800444444, #444);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
        letter-spacing: -0.12px;
        .individual-fee {
            display: flex;
            justify-content: space-between;
        }
    }
}
