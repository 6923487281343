a {
    color: $os-black;
}

h1 {
    font-family: "Ginto";
    margin-bottom: 18px;
    @include font(h1);
    &.reduced {
        @include font(h1-reduced);
    }
    &.gradient {
        @include gradient;
        @include gradient-clip;
    }
}

p, div.paragraph {
    &:not(.intro) {
        @include font(p);
    }
    &.intro {
        @include font(intro);
        font-weight: 500;
    }
    &.helper {
        @include font(aux);
    }
    &.helper-light {
        @include font(aux);
        color: $os-gray;
        opacity: 0.7;
    }
}

#header h1 {
    text-align: center;
    @include media-breakpoint-up(md) {
        @include font(h1-header);
    }
}

h2 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 130%; /* 23.4px */
    &.gradient {
        @include gradient;
        @include gradient-clip;
    }
    &.italic {
        font-style: italic;
    }
    &.ginto-header {
        font-family: Ginto;
    }
    &.large-h2 {
        font-size: 24px;
    }
}

#header p {
    text-align: center;
    @include media-breakpoint-up(md) {
        @include font(p-header);
    }
}
