.alert-danger {
    display: flex;
}

.icon-error {
    width: 16px;
    height: 16px;
}
.total-income-amount-error-message {
    padding-left: 14px;    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #CF0000;
}