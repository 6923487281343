@media only screen and (max-width: 513px) {
    .card-offer-container {
        border: 0px solid !important;
        margin: auto !important;
    }
}

.card-offer-container {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid var(--Monochrome-Black-100, #F4F4F4);
    background: #FFF;
    box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.05);

    .card-details-container {
        display: flex;
        padding: 12px 0px 8px 0px;
        align-items: flex-start;
        gap: 18.54px;
        align-self: stretch;

        .card {
            display: flex;
            width: 79px;
            height: 49.605px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 2.928px;
        }

        .card-offer-title {
            align-self: stretch;
            color: var(--solids-blacks-black-1000151515, #151515);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%;
        }

        .card-offer-description {
            align-self: stretch;
            color: var(--solids-blacks-black-1000151515, #151515);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
    }

    .card-offer-paragraph {
            align-self: stretch;
            color: var(--Primary-Base-Black, #000);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.36px;
    }

    .center-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    .card-offer-button {
        display: flex;
        height: 54px;
        padding: 14px 36px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 50px;
        background: var(--Primary-Base-Black, #000);
        width: 100%;
        margin-bottom: 24px;

        .card-offer-button-text {
            color: #FFF;
            text-align: center;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            margin: 0px !important;
        }
    }

    .close-button {
        color: #518FEC;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;

        .close-button:focus {
            outline: none;
        }
    }

    .benefits-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        margin-bottom: 24px;

        .benefit-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
        }

        .divider {
            width: 100%;
            height: 1px;
            opacity: 0.5;
            background: #D0D0D0;
        }

        .benefit-description {
            color: var(--Monochrome-Black, #151515);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.14px;
        }

        .benefit-value {
            color: #3378F7;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.15px;
        }
    }

    .card-bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        opacity: 0.5;

        .card-bottom-text {
            color: var(--Monochrome-Black, #151515);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.1px;
        }

        .card-bottom-text-underline {
            color: var(--Monochrome-Black, #151515);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.1px;
            text-decoration-line: underline;
        }
    }
}