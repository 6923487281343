@mixin list-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        padding: 0;
        margin: 0;
    }
}

@mixin font($font-style) {
    @if $font-style == "h1" {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
        font-weight: 400;
    } @else if $font-style == "h1-reduced" {
        font-size: 28px;
        line-height: 36px;
    } @else if $font-style == "h1-desktop" {
        font-size: 36px;
        line-height: 44px;
    } @else if $font-style == "h1-header" {
        text-align: center;
    } @else if $font-style == "intro" {
        font-size: 18px;
        line-height: 24px;
    } @else if $font-style == "p" {
        font-size: 15px;
        line-height: 24px;
    } @else if $font-style == "p-header" {
        text-align: center;
    } @else if $font-style == "aux" {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.02em;
    } @else if $font-style == "label-sm" {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.02em;
    }
}

// @mixin font($font-style) {
//     @if $font-style == "h1" {
//         font-size: 28px;
//         font-size: 32px;
//         line-height: 40px;
//         letter-spacing: -0.02em;
//         font-weight: 400;

//     } @else if $font-style == "h1-reduced" {
//         font-size: 28px;
//         line-height: 36px;
//         font-weight: 700;
//         letter-spacing: -0.01em;
//
// }
@mixin gradient {
    background: linear-gradient(
        90deg,
        #ff8469 0%,
        #fe6060 22.71%,
        #d14ada 40.32%,
        #7c41ff 56.81%,
        #5566ff 78.69%,
        #3378f7 100%
    );
}
@mixin gradient-light {
    background: linear-gradient(
        90deg,
        #ffe6e1 0%,
        #ffdfdf 22.71%,
        #f6dbf8 40.32%,
        #e5d9ff 56.81%,
        #dde0ff 78.69%,
        #d6e4fd 100%
    );
}
@mixin gradient-border {
    @include gradient;
    border: none;
    &:after {
        @include gradient;
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        filter: blur(6px);
        opacity: 0.5;
    }
}

@mixin gradient-cover {
    background: #fff;
    display: block;
    border-radius: 3px;
}
@mixin gradient-clip {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
@mixin rule($position: "full", $color: $os-gray) {
    @if $position == "top" {
        border-top: 1px solid $color;
    } @else if $position == "right" {
        border-right: 1px solid $color;
    } @else if $position == "bottom" {
        border-bottom: 1px solid $color;
    } @else if $position == "left" {
        border-left: 1px solid $color;
    } @else if $position == "full" {
        border: 1px solid $color;
    }
}
