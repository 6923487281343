.check-application {
    .alert-danger {
        color: #CF0000;
        background-color: #FFEDED;
        border-color: #f5c2c7;
    }
}

.application-logo {
    border-bottom: 1px solid #A8A8A8;
    width: 100%;
    img {
        position: relative;
        left: -15px;
    }
}

.application-h1-padding {
    padding-top: 25px;
}

// OpenSky card //
.application-card-name 
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 274px;
    height: 36px;

    flex: none;
    order: 0;
    flex-grow: 0;

    color: #151515;
    border-bottom: 1px solid #A8A8A8;
    padding-bottom: 21px;
    width: 100%;

    img {
        width: 48px;
    }
}

.application-datetime {
    padding-top: 18px;
    padding-bottom: 18px;
}

/*
// OpenSky card //

// Auto layout //
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;

width: 226px;
height: 36px;


// Inside auto layout //
flex: none;
order: 0;
flex-grow: 0;


// Plus Card //

width: 56px;
height: 35.16px;


// Inside auto layout //
flex: none;
order: 0;
flex-grow: 0;


// Card front vector //

position: absolute;
left: 0%;
right: 37.5%;
top: 99.54%;
bottom: -158.8%;

transform: rotate(-90deg);


// Card //

box-sizing: border-box;

position: absolute;
width: 35px;
height: 56px;
left: 0px;
top: 35px;

background: linear-gradient(202.85deg, #3378F7 34.16%, #ACCCFF 103.63%);
border-radius: 1.95639px;
transform: rotate(-90deg);


// Smart Chip //

position: absolute;
width: 7.23px;
height: 5.16px;
left: 6.2px;
top: 11.92px;



// Chip //

box-sizing: border-box;

position: absolute;
width: 7.23px;
height: 5.16px;
left: 6.2px;
top: 11.92px;

background: linear-gradient(116.15deg, #F7F1B4 0.37%, #BEA475 100.7%);
border: 0.114767px solid #47453F;
border-radius: 0.918134px;


// Circuits //

position: absolute;
width: 7.23px;
height: 5.13px;
left: 6.2px;
top: 11.94px;

background: #47453F;


// Vector 4 //

position: absolute;
width: 2.58px;
height: 1.78px;
left: 10.85px;
top: 13.64px;

border: 0.114767px solid #47453F;


// Vector 2 //

position: absolute;
width: 7.23px;
height: 1.66px;
left: 6.2px;
top: 15.36px;

border: 0.114767px solid #47453F;


// Vector 3 //

position: absolute;
width: 0px;
height: 1.84px;
left: 8.78px;
top: 13.58px;

border: 0.114767px solid #47453F;


// Vector 1 //

position: absolute;
width: 4.65px;
height: 1.66px;
left: 6.2px;
top: 11.98px;

border: 0.114767px solid #47453F;


// VISA Logo //

position: absolute;
width: 12.39px;
height: 4.01px;
left: 41.69px;
top: 29.25px;



// polygon9 //

position: absolute;
left: 35.19%;
right: 51.65%;
top: 1.75%;
bottom: 1.55%;

background: #FFFFFF;


// path11 //

position: absolute;
left: 48.35%;
right: 27.37%;
top: 0%;
bottom: 0.11%;

background: #FFFFFF;


// path13 //

position: absolute;
left: 70.86%;
right: 0.1%;
top: 1.75%;
bottom: 1.55%;

background: #FFFFFF;


// path15 //

position: absolute;
left: 8.45%;
right: 63.01%;
top: 1.75%;
bottom: 1.65%;

background: #FFFFFF;


// path17 //

position: absolute;
left: 0%;
right: 80.36%;
top: 1.75%;
bottom: 45.68%;

background: #FFFFFF;


// 4000 1234 5678 9010 //

position: absolute;
visibility: hidden;
width: 31px;
height: 4px;
left: 5.4px;
top: 18.19px;

font-family: 'Moderat';
font-style: normal;
font-weight: 400;
font-size: 3.38179px;
line-height: 4px;
// identical to box height //
letter-spacing: -0.03em;

color: #EDEDED;



// YOUR NAME HERE //

position: absolute;
visibility: hidden;
width: 22px;
height: 3px;
left: 5.72px;
top: 29.31px;

font-family: 'Moderat';
font-style: normal;
font-weight: 400;
font-size: 2.73145px;
line-height: 3px;
letter-spacing: -0.065em;

color: #EDEDED;



// 3/20 //

position: absolute;
visibility: hidden;
width: 6px;
height: 3px;
left: 26.47px;
top: 25.93px;

font-family: 'Moderat';
font-style: normal;
font-weight: 400;
font-size: 2.73145px;
line-height: 3px;
letter-spacing: -0.03em;

color: #EDEDED;



// 4000 //

position: absolute;
visibility: hidden;
width: 3px;
height: 2px;
left: 6.57px;
top: 22.16px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 1.30069px;
line-height: 2px;
// identical to box height //
letter-spacing: -0.025em;

color: #D4D4D4;



// GOOD THRU //

position: absolute;
visibility: hidden;
width: 4px;
height: 2px;
left: 21.92px;
top: 25.99px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 1.30069px;
line-height: 1px;
// identical to box height //
letter-spacing: -0.03em;

color: #D4D4D4;



// OpenSky logo //

position: absolute;
width: 6.24px;
height: 18.99px;
left: 2.6px;
top: 8.76px;

transform: rotate(-90deg);


// OpenSky //

position: absolute;
left: 3.83%;
right: -190.64%;
top: 3.01%;
bottom: 70.33%;

background: #FFFFFF;


// ® //

position: absolute;
left: 284.66%;
right: -203.02%;
top: 20.08%;
bottom: 73.89%;

background: #FFFFFF;


// Frame //

position: absolute;
width: 12.75px;
height: 4px;
left: 41.11px;
top: 3px;



// Vector //

position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

background: #FFFFFF;


// Group //

position: absolute;
left: 14.48%;
right: 14.44%;
top: 23.08%;
bottom: 22.1%;



// Vector //

position: absolute;
left: 14.48%;
right: 70.2%;
top: 23.79%;
bottom: 22.9%;

background: #FFFFFF;


// Vector //

position: absolute;
left: 33.35%;
right: 52.66%;
top: 23.79%;
bottom: 22.84%;

background: #FFFFFF;


// Vector //

position: absolute;
left: 50.07%;
right: 33.2%;
top: 23.79%;
bottom: 22.12%;

background: #FFFFFF;


// Vector //

position: absolute;
left: 70.46%;
right: 14.44%;
top: 23.08%;
bottom: 22.1%;

background: #FFFFFF;


// Card header //

// Auto layout //
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 2px;

width: 154px;
height: 36px;


// Inside auto layout //
flex: none;
order: 1;
flex-grow: 0;


// OpenSky Plus //

width: 154px;
height: 36px;

// Mobile/H3c Inter M //
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
// identical to box height, or 150% //
letter-spacing: -0.02em;
font-feature-settings: 'kern' off;

// Solids/Blacks/Black 1000 (151515) //
color: #151515;


// Inside auto layout //
flex: none;
order: 0;
flex-grow: 0;


// No annual fee //

display: none;
width: 117px;
height: 30px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
// identical to box height, or 167% //
display: flex;
align-items: center;
letter-spacing: -0.01em;

// Solids/Blacks/Black 1000 (151515) //
color: #151515;


// Inside auto layout //
flex: none;
order: 1;
flex-grow: 0;
*/
