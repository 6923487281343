.disclosures {
    .disclosure-tabs {
        &.nav-tabs {
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
    
            .nav-item {
                white-space: nowrap;
                margin-right: 15px;

                button {
                    border: 1px solid $os-gray;
                    margin-bottom: 7px;
                    border-radius: 5px;
                    color: $os-gray;

                    &.active {
                        // @include gradient-border;
                    }
                }
            }
        }
    }
    
    .tab-content {
        margin-top: 2rem;

        .disclosure-header {
            .section-title {
                font-size: 15px;
                line-height: 24px;
                font-weight: 600;
                margin-bottom: 0;
                white-space: nowrap;
            }
            .download-button {
                padding: 0;
                text-align: left;
            }
        }
    
        .card {
            height: 750px;
            margin-top: 1rem;

            .card-body {
                width: 100%;
                height: 100%;
                overflow: hidden;
                padding: 0;

                .disclosure-content {
                    width: 100%;
                    height: 100%;
                    overflow: auto;

                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
